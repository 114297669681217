import axios from 'axios';
import { LoginResponse, UserData } from '../types';
import { apiClient } from './apiClient';

//**********************************************/
// ****  LOGIN
//**********************************************/
export const loginApiCall = async (email: string, password: string): Promise<any | null> => {
  try {

    const response = await apiClient.post<LoginResponse>('/api/login', {
      email,
      password,
    });
       
    return response;
  } catch (error) {
  
    console.error(error)
    throw error;
    return null;
  }
};


export const getUserData = async (): Promise<any | null> => {
  try {
    const response = await apiClient.get<UserData>('/api/user');
   
    return response;

  } catch (error) {
    console.error(error)
    return null;
  }
};

