// src/components/TopBar.tsx
import React from 'react';
import DropDownMenu from './DropDownMenu';


const TopBar: React.FC = () => {
  return (
    <div className="top-bar">
    <div className="logo-div">
    λimeframe
    </div>
    <div className="header-info">
       {/* Offcanvas toggle button (only visible on mobile)*/}
        <button className="d-md-none mobmenu" data-bs-toggle="offcanvas" data-bs-target="#sidebar" aria-controls="sidebar" style={{ border: 'none', background: 'none', padding: 0 }}>
            <i className="bi bi-list" style={{ fontSize: '1.5rem' }}></i>
        </button>

       {/*User Info Dropdown Icon*/}
       <DropDownMenu />

    </div>
</div>
  );
};

export default TopBar;
