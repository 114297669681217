import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate, useLocation } from 'react-router-dom';
import { RootState } from '../redux/reducers';
import { CONFIG } from './config';

interface RoleBasedRouteProps {
  children: JSX.Element;
  allowedRoles: string[];
}

const RoleBasedRoute: React.FC<RoleBasedRouteProps> = ({ children, allowedRoles }) => {
  const role = useSelector((state: RootState) => state.auth.role);
  const isAuthenticated = useSelector((state: RootState) => state.auth.isAuthenticated);
  const location = useLocation();

  if (!isAuthenticated || !allowedRoles.includes(role)) {
    return <Navigate to={CONFIG.urls.getLoginUrl()} state={{ from: location }} replace />;
  }

  return children;
};

export default RoleBasedRoute;