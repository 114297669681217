// src/components/Footer.tsx
import React from 'react'

const Footer: React.FC = () => {
  return (
    <div className="footer">
        <p>&copy; 2024 <a href="https://www.limeframe.gr" target="_blank" title="Ανάπτυξη εφαρμογής λimeframe">λimeframe</a></p>
    </div>

  )
}

export default Footer