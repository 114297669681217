import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { setAuthenticated, resetRole } from '../../redux/actions/authAction';
import { useAuth } from '../../utils/authContext';
import { CONFIG } from '../../utils/config';


const Logout: React.FC = () => {
    const dispatch = useDispatch<any>();
  const navigate = useNavigate();
  const {logout } = useAuth();

  useEffect(() => {
    const performLogout = async () => {
      await logout();
      navigate(CONFIG.urls.getLoginUrl());
    };
  
    performLogout();
  }, [logout, navigate]);

  return null;
};

export default Logout;

