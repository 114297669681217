import React, { useState, useCallback, useEffect } from 'react';
import { getSubscriptionDetails } from '../../utils/apicall/clientuserApi';
import { SubscriptionObject } from '../../utils/types';
import { getBillingCycleLabel } from '../../utils/function';

interface SubscriptionDetailsProps {
  id: string;
}

const SubscriptionDetails: React.FC<SubscriptionDetailsProps> = ({ id }) => {
  const [subscription, setSubscription] = useState<SubscriptionObject | null>(null);

  const loadData = useCallback(async () => {
    try {
      if (id) {
        const response = await getSubscriptionDetails(id);
        if (response) {
          setSubscription(response);
        } else {
          console.warn('No subscription data found for this ID:', id);
        }
      }
    } catch (error) {
      console.error('An error occurred while fetching data.', error);
    }
  }, [id]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  if (!subscription) {
    return <p>Loading...</p>;
  }

  const {
    domain,
    next_due_date,
    billing_cycle,
    product_price,
    tax,
    product: { title, description },
  } = subscription.subscription;

  return (
    <div>
      <div className="container mt-4">
        <div className="page-header border-bottom border-1 mb-4">
          <h2 className="mb-4 h5 page-title text-danger">{title}</h2>
          
          {description ? (
            <div
              dangerouslySetInnerHTML={{ __html: description }}
            />
          ) : (
            <p></p>
          )}
        </div>

        <div className="card-body">
          <ul className="list-group">
            <li className="list-group-item">
              <strong>Domain name:</strong> {domain}
            </li>
            <li className="list-group-item">
              <strong>Ημερομηνία λήξης υπηρεσίας:</strong> {new Date(next_due_date).toLocaleDateString()}
            </li>
            <li className="list-group-item">
              <strong>Κύκλος ανανέωσης:</strong> {getBillingCycleLabel(billing_cycle)}
            </li>
            <li className="list-group-item">
              <strong>Τιμή κύκλου υπηρεσίας:</strong> {parseFloat(product_price).toFixed(2)}€ ({(parseFloat(product_price) + parseFloat(tax)).toFixed(2)}€ με ΦΠΑ)
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default SubscriptionDetails;
