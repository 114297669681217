export const CONFIG = {
    urls: {
      apiEndpoint: 'https://wecosys2.limeframe.gr',
      Default_API_TOKEN: '',
      getLoginUrl: () => `/login`,
      getLogoutUrl: () => `/logout`,
  
  
       // navbarAdmin
       getDashboardClientUrl: () => `/dashboard`,
       getSubscriptionDetailsClientUrl:(id: string | number) => `/subscription/${id}`,
       getSubscriptionsClientUrl:() => `/subscriptions/`,

   

    },
    
  };