import { useState, useEffect } from 'react'
import { BrowserRouter as Routers, Routes , Route, Navigate, useNavigate } from 'react-router-dom'
import { useAuth } from '../../utils/authContext';
import { getUserData, loginApiCall } from '../../utils/apicall/loginApi';
import axios from 'axios';
import { apiClient } from '../../utils/apicall/apiClient';
import { CONFIG } from '../../utils/config';


const Login: React.FC = () => {
  const navigate = useNavigate();
  const { isAuthenticated, loginauth } = useAuth();
  const [rememberMe, setRememberMe] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");

  useEffect(() => {
    // If the user is already authenticated, redirect to the dashboard
    if (isAuthenticated) {
      navigate(CONFIG.urls.getDashboardClientUrl());
    }
  }, [isAuthenticated, navigate]);

  const handleLogin = async (event: React.FormEvent) => {
    event.preventDefault();


   try {
      const response = await  loginApiCall(email, password); // Implement your login API call
      // Check if response and response.data are defined
    if (response && response.data && response.data.token) {
     await loginauth(response.data.token, response, rememberMe ); // Call the login method from the auth context
      navigate(CONFIG.urls.getDashboardClientUrl());
    }
  } catch (error) {
    console.error('Login failed', error);
    navigate(CONFIG.urls.getLogoutUrl());
  // setEmailError("Invalid email or password");
   // setPasswordError("Invalid email or password");
    // Handle login error (e.g., show error message)
  }
};


    return(
      <div className="loginbody">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-12 col-md-6 col-lg-5">
            <div className="card login-card">
              <h1 className="login-card-title text-center">Σύνδεση</h1>
              <form onSubmit={handleLogin}>
                <div className="mb-3">
                  <label htmlFor="email" className="form-label">Email διεύθυνση</label>
                  <input
                    type="email"
                    className="form-control"
                    id="email"
                    name="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="password" className="form-label">Συνθηματικό</label>
                  <input
                    type="password"
                    className="form-control"
                    id="password"
                    name="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    required
                  />
                </div>
                <div className="mb-3 form-check">
                <input
                      type="checkbox"
                      className="form-check-input"
                      id="remember"
                      name="remember"
                      checked={rememberMe}
                      onChange={(e) => setRememberMe(e.target.checked)}
                    />
                  <label className="form-check-label" htmlFor="remember">Να με θυμάσαι</label>
                </div>
                <button type="submit" className="btn btn-primary w-100">Σύνδεση</button>
                <div className="text-center mt-3">
                  <a href="#">Ξέχασα το συνθηματικό μου</a>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
      


export default Login