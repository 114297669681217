import React from 'react';
import { CONFIG } from '../utils/config';

const DropDownMenu: React.FC = () => {
  // Safely retrieve user data from sessionStorage
  const storedUserData = sessionStorage.getItem('userInfo');
  
  // Initialize user data as undefined in case parsing fails
  let userInfo: { name?: string; email?: string } = {};

  if (storedUserData) {
    try {
      // Parse the JSON string safely
      userInfo = JSON.parse(storedUserData);
    } catch (error) {
      console.error('Error parsing userInfo from sessionStorage:', error);
      userInfo = { name: '', email: '' }; // Provide fallback
    }
  }

  // Access name and email safely
  const userName = userInfo?.name || '';
  const userEmail = userInfo?.email || '';

  return (
    <div className="dropdown">
      <a
        href="#"
        className="dropdown-toggle text-decoration-none mobmenu"
        id="dropdownProfileMenu"
        data-bs-toggle="dropdown"
        aria-expanded="false"
      >
        <i className="bi bi-person-circle" style={{ fontSize: '1.5rem' }}></i>
      </a>
      <ul
        className="dropdown-menu dropdown-menu-end userflymenu"
        aria-labelledby="dropdownProfileMenu"
      >
        <li className="p-3 border-bottom">
          <span className="fw-bold">{userName}</span>
          <br />
          <span className="text-muted small">{userEmail}</span>
        </li>
        <li>
          <a className="dropdown-item" href="#">
            Προφίλ
          </a>
        </li>
        <li>
          <a className="dropdown-item" href="#">
            Αλλαγή κωδικού
          </a>
        </li>
        <li>
          <hr className="dropdown-divider" />
        </li>
        <li>
          <a className="dropdown-item text-danger" href={CONFIG.urls.getLogoutUrl()}>
            Αποσύνδεση
          </a>
        </li>
      </ul>
    </div>
  );
};

export default DropDownMenu;
