
import React, { useState, useCallback, useEffect } from 'react';



const DashboardPage: React.FC  = ( ) => {
   

  return (
        
    <div className="content">
    <div className="container mt-4">
      <div className="page-header border-bottom border-1 mb-4">
        <h2 className="mb-4 h5 page-title text-primary">
          λimeframe
        </h2>
        </div>
    </div>
  </div>
  );
};

export default DashboardPage;
