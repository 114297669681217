import React from 'react';

interface NavItemProps {
  name: string;
  icon: string;
  href: string;
  submenuId?: string;
  submenu?: { name: string; href: string }[];
}

const NavItem: React.FC<NavItemProps> = ({ name, icon, href, submenuId, submenu }) => {
    return (
      <li className="nav-item">
        <a 
          className={`nav-link ${submenu ? 'has-submenu collapsed' : ''}`} 
          data-bs-toggle={submenu ? "collapse" : undefined} 
          href={href} 
          role="button" 
          aria-expanded="false" 
          aria-controls={submenuId}>
          <span><i className={`bi ${icon}`}></i> {name}</span>
          {submenu && <i className="bi bi-chevron-right"></i>}
        </a>
        {submenu && (
          <div className="collapse" id={submenuId}>
            <ul className="nav flex-column">
              {submenu.map((subItem, index) => (
                <li className="nav-item" key={index}>
                  <a className="nav-link" href={subItem.href}>{subItem.name}</a>
                </li>
              ))}
            </ul>
          </div>
        )}
      </li>
    );
  };
export default NavItem;

