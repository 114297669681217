import React from "react";

const SidebarFooter: React.FC = () => {
  return (
    <div className="sidebar-footer">
    <p></p>
</div>
  );
};

export default SidebarFooter;
