import React, { createContext, useState, useContext, ReactNode, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setAuthenticated, setRole, resetRole, setUserInfo } from '../redux/actions/authAction';
import { UserData } from './types';
import { getUserData } from './apicall/loginApi';
import { UserInfo, userInfo } from 'os';




interface AuthContextType {
  isAuthenticated: boolean;
  role: string;
  loginauth: (token: string, response: any, rememberMe: boolean) => void;
  logout: () => void;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};

export const AuthProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const dispatch = useDispatch<any>();
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(() => {
    const token = sessionStorage.getItem('token');
    return !!token;
  });
  const [role, setRoleState] = useState<string>(() => {
    const storedRole = sessionStorage.getItem('role') || 'guest'; // Provide a default value
    return storedRole;
  });

  useEffect(() => {
    if (isAuthenticated) {
      dispatch(setAuthenticated(true));
      dispatch(setRole(role));
    } else {
      dispatch(setAuthenticated(false));
      dispatch(resetRole());
    }
  }, [isAuthenticated, role, dispatch]);


  const loginauth = async (token: string, response: any, rememberMe: boolean) => {
   
      sessionStorage.setItem('token', token); // Persist token in sessionStorage
 
    
    const responseUserData = await getUserData(); 

    const userdata= responseUserData.data;

    //console.log(userdata);

    const userdatalocal = {
      name: userdata.user.name,
      email: userdata.user.email
    };
    const roleNames = userdata.user.roles.map((role: { name: any; }) => role.name).join(', '); // Joins role names with a comma

   sessionStorage.setItem('role', token); // Persist token in sessionStorage
   sessionStorage.setItem('userInfo', JSON.stringify(userdatalocal)); // Persist token in sessionStorage
   

    // Set other states and dispatch action
    setRoleState(roleNames); // Assuming setRoleState is for managing role state
    setIsAuthenticated(true); // Assuming it manages authentication state
    dispatch(setUserInfo(userdatalocal)); // Dispatch the user information
  };
  


  const logout = async () => {
    return new Promise<void>((resolve) => {
      sessionStorage.removeItem('token');
      sessionStorage.removeItem('role');
      sessionStorage.removeItem('userInfo');
      setIsAuthenticated(false);
      setRoleState('guest');
      dispatch(resetRole());
      resolve();
    });
  };



  return (
    <AuthContext.Provider value={{ isAuthenticated, role, loginauth, logout }}>
      {children}
    </AuthContext.Provider>
  );
};
