import React, { useState } from 'react';
import { CONFIG } from '../../utils/config';
import { getBillingCycleLabel } from '../../utils/function';
import { Modal } from 'react-bootstrap';
import SubscriptionDetails from './subscriptionsdetails';


interface SubscriptionCardProps {
  id: number;
  title: string;
  renewalCycle: string;
  priceBeforeTax: number;
  priceAfterTax: number;
  next_due_date:string;
}

const SubscriptionCard: React.FC<SubscriptionCardProps> = ({ id, title, renewalCycle, priceBeforeTax, priceAfterTax, next_due_date }) => {
  const [showModal, setShowModal] = useState(false);

  const handleDetailsClick = () => {
    setShowModal(true);
  };

  const handleClose = () => {
    setShowModal(false);
  };

  return (
    <div className='col-md-6'>
      <div className="card mb-3">
        <div className="card-body d-flex justify-content-between align-items-center">
          <div>
            <h5 className="card-title text-primary mb-1">{title}</h5>
            <p className="mb-1 text-muted">
              <strong>Κύκλος ανανέωσης: </strong>{getBillingCycleLabel(renewalCycle)}
            </p>
            <p className="mb-1 text-secondary">
              <strong>Τιμή κύκλου: </strong>{priceBeforeTax.toFixed(2)} € ({priceAfterTax.toFixed(2)} € με ΦΠΑ 24%)
            </p>
            <p className="mb-1 text-primary">
              <strong>Ημερομηνία ανανέωσης: </strong><span className="badge text-bg-warning">{next_due_date}</span>
            </p>
          </div>
          <div>
            <button onClick={handleDetailsClick} className="btn btn-outline-primary btn-sm">
              Λεπτομέρειες
            </button>
          </div>
        </div>
      </div>

      {/* Modal to show SubscriptionDetails */}
      <Modal show={showModal} onHide={handleClose} size="lg" >

        <Modal.Body>
          <SubscriptionDetails id={id.toString()} /> {/* Pass the id as a prop */}
        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn-secondary" onClick={handleClose}>
            Κλείσιμο
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default SubscriptionCard;
