import React from "react";
import NavItem from "./NavItem";
import { Link, NavLinkWithSubmenu, navItems} from "../utils/data/NavItemsData";


// Type guard function to determine if a link has a submenu
function hasSubmenu(link: Link): link is NavLinkWithSubmenu {
  return (link as NavLinkWithSubmenu).submenu !== undefined;
}

const SidebarMobile: React.FC = () => {
  return (
    <div className="offcanvas offcanvas-end d-md-none" tabIndex={-1} id="sidebar" aria-labelledby="offcanvasSidebarLabel">
      <div className="offcanvas-header">
        <h5 className="offcanvas-title" id="offcanvasSidebarLabel">Μενού</h5>
        <button type="button" className="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
      </div>
      <div className="offcanvas-body">
        <ul className="nav flex-column">
          {navItems.map((section, index) => (
            <React.Fragment key={index}>
              {section.sectionTitle && <li className="nav-section-title">{section.sectionTitle}</li>}
              {section.links.map((link, idx) => (
                hasSubmenu(link) ? (
                  <NavItem
                    key={idx}
                    name={link.name}
                    icon={link.icon}
                    href={link.href}
                    submenuId={link.submenuId}
                    submenu={link.submenu}
                  />
                ) : (
                  <NavItem
                    key={idx}
                    name={link.name}
                    icon={link.icon}
                    href={link.href}
                  />
                )
              ))}
            </React.Fragment>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default SidebarMobile;
