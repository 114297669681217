// SidebarDesktop.tsx
import React from "react";
import NavItem from "./NavItem";

import SidebarFooter from "./Sidebar-footer";
import { Link, NavLinkWithSubmenu, navItems } from "../utils/data/NavItemsData";

// Type guard function to determine if a link has a submenu
function hasSubmenu(link: Link): link is NavLinkWithSubmenu {
  return (link as NavLinkWithSubmenu).submenu !== undefined;
}

const SidebarDesktop: React.FC = () => {
  return (
    <div className="sidebar d-none d-md-block" id="sidebar-desktop">
       <ul className="nav flex-column p-1">
          {navItems.map((section, index) => (
            <React.Fragment key={index}>
              {section.sectionTitle && <li className="nav-section-title">{section.sectionTitle}</li>}
              {section.links.map((link, idx) => (
                // Type guard to check if the link has a submenu
                hasSubmenu(link) ? (
                  <NavItem
                    key={idx}
                    name={link.name}
                    icon={link.icon}
                    href={link.href}
                    submenuId={link.submenuId}
                    submenu={link.submenu}
                  />
                ) : (
                  <NavItem
                    key={idx}
                    name={link.name}
                    icon={link.icon}
                    href={link.href}
                  />
                )
              ))}
            </React.Fragment>
          ))}
        </ul>
        <SidebarFooter />
      </div>
  );
};

export default SidebarDesktop;
