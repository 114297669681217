import React, { ReactNode, useEffect, useState } from 'react';
import TopBar from '../components/TopBar';
import SidebarMobile from '../components/Sidebar-mobile';
import SidebarDesktop from '../components/Sidebar-desktop';
import Footer from '../components/footer';

//import '../css/codocadmin.css'


interface LayoutProps {
    children: ReactNode;
    activeSection?: string; // Add if needed
  }

const ClientLayout: React.FC<LayoutProps> = ({ children, activeSection  }) => {
  
  return (
    <div className="wrapper">
      <TopBar />
      <SidebarMobile />
      <section className="main-page">
        <SidebarDesktop />
        {children}
  
      </section>
      <Footer />
      </div>
 
  );
};

export default ClientLayout