import React from 'react';
import logo from './logo.svg';
import { useSelector } from 'react-redux';
import LayoutWrapper from './layout/LayoutWrapper';
import { Routes, Route } from 'react-router-dom';
import NotFound from './pages/common/notFound';
import { CONFIG } from './utils/config';
import Login from './pages/common/login';
import RoleBasedRoute from './utils/RoleBasedRoute';
import Logout from './pages/common/logout';
import SubscriptionDetails from './pages/clients/subscriptionsdetails';
import DashboardPage from './pages/clients/dashboard';
import SubscriptionsList from './pages/clients/subscriprtionslist';


function App() {

  return (
    <LayoutWrapper activeSection={''}>
      
      <Routes>
        <Route path="*" element={<NotFound />} />
        <Route path="/" element={<Login />} />
        <Route path={CONFIG.urls.getLoginUrl()} element={<Login />} />
        <Route 
          path={CONFIG.urls.getLogoutUrl()} 
          element={
            <RoleBasedRoute allowedRoles={[ 'admin', 'client']}>
              <Logout />
            </RoleBasedRoute>
          } 
        />
        <Route 
          path={CONFIG.urls.getDashboardClientUrl()} 
          element={
            <RoleBasedRoute allowedRoles={['admin', 'client']}>
              <DashboardPage />
            </RoleBasedRoute>
          } 
        />
      

        <Route 
          path={CONFIG.urls.getSubscriptionsClientUrl()} 
          element={
            <RoleBasedRoute allowedRoles={['admin', 'client']}>
              <SubscriptionsList />
            </RoleBasedRoute>
          } 
        />
       

     

       </Routes>

      
    </LayoutWrapper>
  );
}

export default App;
