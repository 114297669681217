import React, { useState, useCallback, useEffect } from 'react';

import { Services, ServiceItem } from '../../utils/types';
import { getSubscriptionList } from '../../utils/apicall/clientuserApi';
import SubscriptionCard from './subscriprtionscard';

const SubscriptionList: React.FC = () => {
    const [subscriptions, setSubscriptions] = useState<Services>({});

    const loadData = useCallback(async () => {
        try {
            const response = await getSubscriptionList();
            if (response) {
                setSubscriptions(response);
            } else {
                console.warn('Expected an object under "services" but got:', response);
            }
        } catch (error) {
            console.error('An error occurred while fetching data.', error);
        }
    }, []);

    useEffect(() => {
        loadData();
    }, [loadData]);

    const isValidDate = (dateString: string) => {
        const date = new Date(dateString);
        return !isNaN(date.getTime());
    };

    return (
      <div className="content">
        <div className="container mt-4">
            <div className="page-header border-bottom border-1 mb-4">
                <h2 className="mb-4 h5 page-title text-primary">Λίστα συνδρομών</h2>
                <p>
                    Δείτε παρακάτω τις ενεργές συνδρομές που έχετε στην εταιρεία μας. <br />
                    Οι συνδρομές είναι ομαδοποιημένες βάση του domain name που αφορούν.
                </p>
            </div>

            {Object.keys(subscriptions).map((domain, index) => (
                <div className="projectList" key={index}>
                    <h4 className="text-danger h4 mb-4 mt-3">{domain}</h4>
                    <div className='row' >
                    {subscriptions[domain]
                        .sort((a: ServiceItem, b: ServiceItem) => {
                            const dateA = isValidDate(a.next_due_date) ? new Date(a.next_due_date).getTime() : Infinity;
                            const dateB = isValidDate(b.next_due_date) ? new Date(b.next_due_date).getTime() : Infinity;
                            return dateA - dateB;
                        })
                        .map((subscription: ServiceItem, subIndex) => {
                            return (
                                <SubscriptionCard
                                    key={subIndex}
                                    id={subscription.id}
                                    title={subscription.product_title}
                                    renewalCycle={subscription.billing_cycle}
                                    priceBeforeTax={parseFloat(subscription.product_price)}
                                    priceAfterTax={parseFloat(subscription.total)}
                                    next_due_date={
                                        isValidDate(subscription.next_due_date)
                                            ? new Date(subscription.next_due_date).toLocaleDateString()
                                            : 'No due date available'
                                    }
                                />
                            );
                        })}
                </div>
                </div>
            ))}
        </div>
      </div>
    );
};

export default SubscriptionList;
