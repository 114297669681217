import { CONFIG } from "../config";

// navItems.ts
export interface NavLink {
    name: string;
    icon: string;
    href: string;
  }
  
  export interface NavLinkWithSubmenu extends NavLink {
    submenuId: string;
    submenu: { name: string; href: string }[];
  }
  
  export type Link = NavLink | NavLinkWithSubmenu;
  
  export interface Section {
    sectionTitle: string | null;
    links: Link[];
  }
  
  export const navItems: Section[] = [
    {
      sectionTitle: null,
      links: [
        { name: "Αρχική", icon: "bi-house", href: CONFIG.urls.getDashboardClientUrl() }
      ]
    },
    {
      sectionTitle: "ΟΙΚΟΝΟΜΙΚΑ ΣΤΟΙΧΕΙΑ",
      links: [
        { name: "Συνδρομές", icon: "bi bi-database-up", href: CONFIG.urls.getSubscriptionsClientUrl() },
        { name: "Ειδοποιητήρια", icon: "bi bi-bell",  href: '#'  },
        { name: "Παραστατικά", icon: "bi bi-receipt",  href: '#'  },
        { name: "Στοιχεία εταιρείας", icon: "bi bi-building",  href: '#'  }
      ]
    },
    {
      sectionTitle: "ΙΣΤΟΤΟΠΟΙ",
      links: [
        { name: "Λίστα ιστοτόπων", icon: "bi bi-globe",  href: '#'  }
      ]
    },
    {
      sectionTitle: "ΕΡΓΑ",
      links: [
        { name: "Λίστα έργων", icon: "bi bi-cast",  href: '#'  }
      ]
    },
    {
      sectionTitle: "ΣΥΜΦΩΝΗΤΙΚΑ",
      links: [
        { name: "Λίστα συμφωνητικών", icon: "bi bi-vector-pen", href: '#' }
      ]
    }
  ];
  