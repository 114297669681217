import React, { ReactNode, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import UserLayout from './UserLayout';
import AdminLayout from './AdminLayout';
import DefaultLayout from './DefaultLayout';
import { RootState } from '../redux/reducers';
import ClientLayout from './ClientLayout';

interface LayoutWrapperProps {
  children: ReactNode,
  activeSection?: string; // Add if needed
}

const LayoutWrapper: React.FC<LayoutWrapperProps> = ({ children, activeSection }) => {
  const role = useSelector((state: RootState) => state.auth.role);
  //const role='admin';
  const location = useLocation();

  useEffect(() => {
   //console.log('LayoutWrapper rendered. Role:', role);
    //console.log(location);

  }, [role]);

  const isAdminPath = location.pathname.startsWith('/wp-admin');

  if (role === 'admin') {
  
    return <ClientLayout activeSection={activeSection}>{children}</ClientLayout>;
  }


  if (role === 'client') {
    return <ClientLayout activeSection={activeSection}>{children}</ClientLayout>;
  }

  return <DefaultLayout activeSection={activeSection}>{children}</DefaultLayout>;
};

export default LayoutWrapper;
