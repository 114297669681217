import { AuthActionTypes,SET_USER_INFO, SET_ROLE, SET_AUTHENTICATED, RESET_ROLE } from "../actions/authAction";
import { UserInfo } from "../../utils/types";


interface AuthState {
  role: string;
  userInfo: UserInfo | null,
  isAuthenticated: boolean;
}

const initialState: AuthState = {
  role: sessionStorage.getItem('role') || 'guest',
  userInfo: null,
  isAuthenticated: !!sessionStorage.getItem('token'),
};

const authReducer = (state = initialState, action: AuthActionTypes): AuthState => {
  switch (action.type) {
    case SET_ROLE:
      sessionStorage.setItem('role', action.payload);
      return {
        ...state,
        role: action.payload,
      };
    case SET_AUTHENTICATED:
      return {
        ...state,
        isAuthenticated: action.payload,
      };
      case SET_USER_INFO:
        return {
          ...state,
          userInfo: action.payload,
        };
    case RESET_ROLE:
      sessionStorage.removeItem('role');
      return {
        ...state,
        role: 'guest',
      };      
    default:
      return state;
  }
};

export default authReducer;
