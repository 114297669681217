import axios from 'axios';
import { apiClient } from './apiClient';

//Με αυτό έρχοναι τα Λίστα έργων
export const getSubscriptionList = async ()=> {
  try {
    const response = await apiClient.get(`api/subscriptions`);
    //console.log(response);
    return response.data;
  } catch (error) {
    return [];
  }
};


export const getSubscriptionDetails = async (id: string)=> {
  try {
    const response = await apiClient.get(`api/subscriptions/${id}`);
    //console.log(response);
    return response.data;
  } catch (error) {
    return [];
  }
};
