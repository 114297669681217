import React, { useEffect } from 'react';
import { ReactNode } from 'react';



interface LayoutProps {
  children: ReactNode;
  activeSection?: string; // Add if needed
}


const DefaultLayout: React.FC<LayoutProps> = ({ children, activeSection }) => {


  return (
  <div>
      {children}
  </div>
  );
};

export default DefaultLayout 
