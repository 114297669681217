import axios, { AxiosError } from 'axios';
import { CONFIG } from '../config';
import { ApiError, handleError } from '../error/error';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/reducers';


// Set default configurations
//axios.defaults.withCredentials = true;
axios.defaults.baseURL = CONFIG.urls.apiEndpoint;

// Axios instance
export const apiClient = axios.create({
  baseURL: axios.defaults.baseURL,
  headers: {
    "Content-Type": "application/json"
  },
});

// Request interceptor for adding Authorization and XSRF tokens
apiClient.interceptors.request.use((config) => {
  const token = sessionStorage.getItem('token') || CONFIG.urls.Default_API_TOKEN;
  if (token) config.headers.Authorization = `Bearer ${token}`;
  return config;
});



 apiClient.interceptors.request.use((config) => {
 
   const  token = sessionStorage.getItem('token')|| CONFIG.urls.Default_API_TOKEN;
  
 
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});


export const apiClientFile = axios.create({
  baseURL: CONFIG.urls.apiEndpoint,
  headers: {
     'Content-Type': 'multipart/form-data',
      'Accept': "application/json"
  },
});

apiClientFile.interceptors.request.use((config) => {
  const  token = sessionStorage.getItem('token')|| CONFIG.urls.Default_API_TOKEN;
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});


const handleUnauthorizedError = () => {
  // Clear any stored tokens
  sessionStorage.removeItem('authToken');
  // Redirect to login page or show a login modal
  window.location.href = CONFIG.urls.getLogoutUrl();
};


// Interceptor to handle responses
const responseInterceptor = (error: AxiosError) => {
  if (error.response) {
    if (error.response.status === 401) {
      // Handle 401 Unauthorized error
      const apiError = new ApiError('Unauthenticated', 401);
      handleError(apiError);
      handleUnauthorizedError();

    } else {
      // Handle other API errors
      const apiError = new ApiError(error.response.statusText || 'An API error occurred', error.response.status);
      handleError(apiError);
    }
  } else if (error.request) {
    // Handle no response from server
    handleError(new ApiError('No response from server', 0));
  } else {
    // Handle other errors
    handleError(new Error(error.message));
  }

  return Promise.reject(error);
};



apiClient.interceptors.response.use((response) => response, responseInterceptor);
apiClientFile.interceptors.response.use((response) => response, responseInterceptor);
